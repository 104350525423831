import type { TranslatedCurrencyInterface } from './currency.model';
import { PriceRequestItemInterface } from './price-request-item.model';
import { UserInterface } from './user.model';

export enum PriceRequestStatusEnum {
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  WAITING_FOR_RESPONSE = 'waiting_for_response',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export const PriceRequestStatusTranslationMap = {
  [PriceRequestStatusEnum.DRAFT]: 'INQUIRIES.PRICE_REQUESTS.STATUS.DRAFT',
  [PriceRequestStatusEnum.IN_PROGRESS]: 'INQUIRIES.PRICE_REQUESTS.STATUS.IN_PROGRESS',
  [PriceRequestStatusEnum.WAITING_FOR_RESPONSE]: 'INQUIRIES.PRICE_REQUESTS.STATUS.WAITING_FOR_RESPONSE',
  [PriceRequestStatusEnum.CANCELED]: 'INQUIRIES.PRICE_REQUESTS.STATUS.CANCELED',
  [PriceRequestStatusEnum.COMPLETED]: 'INQUIRIES.PRICE_REQUESTS.STATUS.COMPLETED',
  [PriceRequestStatusEnum.EXPIRED]: 'INQUIRIES.PRICE_REQUESTS.STATUS.EXPIRED',
};

export interface PriceRequestInterface {
  id: number;
  title: string;
  status: PriceRequestStatusEnum;
  pricelistPrice: number | null;
  customMadePriceRequestItems: PriceRequestItemInterface[];
  currency: TranslatedCurrencyInterface;
  updatedAtTimestamp: number;
  expirationDateTimestamp: number | null;
  cancellationDateTimestamp: number | null;
  jiraIssueKey: string | null;
  isLocked: boolean | null;
  user?: UserInterface;
  salePrice: number | null;
}

export interface LastUpdatedPriceRequestInterface {
  id: number;
}
