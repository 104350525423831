import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderArticleSelectInputComponent } from './order-article-select-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { MaterialsListModule } from "../materials-list/materials-list.module";
import { CatalogueModule } from "../../catalogue/catalogue.module";

@NgModule({
  declarations: [OrderArticleSelectInputComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, MaterialsListModule, CatalogueModule],
  exports: [OrderArticleSelectInputComponent],
})
export class OrderArticleSelectInputModule { }
