import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialsListComponent } from './materials-list.component';
import { UncacheableResourceModule } from "../../uncacheable-resource/uncacheable-resource.module";
import { SharedModule } from '../../shared/shared.module';
import { MaterialsListItemComponent } from './materials-list-item/materials-list-item.component';

@NgModule({
    declarations: [
        MaterialsListComponent,
        MaterialsListItemComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        UncacheableResourceModule,
    ],
    exports: [
        MaterialsListComponent,
        MaterialsListItemComponent,
    ]
})
export class MaterialsListModule { }
