import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateInputComponent } from './date-input.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MinDateNowDirective } from './min-date-now.directive';



@NgModule({
  declarations: [DateInputComponent, MinDateNowDirective],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TranslateModule
  ],
  exports: [
    DateInputComponent,
    MinDateNowDirective
  ]
})
export class DateInputModule { }


