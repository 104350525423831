import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Options } from '@popperjs/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PropertyImageSizes } from '../../../../core/enums/property-image-sizes.enum';
import { UuidGenerator } from '../../../../core/util/uuid.generator';
import { PopoverRef } from '../../../../ui-elements/popover/popover-ref';
import { PopoverService } from '../../../../ui-elements/popover/popover.service';
import { UncachePipe } from '../../../../uncacheable-resource/uncache.pipe';
import { ArticlePropertyProperty, ArticlePropertyValueValueInterface, ValuesGroupedByMaterialFamilyInterface } from '../../product.model';
import { PropertyValueGroupsService } from './property-value-groups.service';
import { InfoPopoversService } from '../info-popovers.service';
import { LocalStorageKeys } from '../../../../core/constants/local-storage-keys.constants';

@Component({
  selector: 'app-property-values-group',
  templateUrl: './property-values-group.component.html',
  styleUrls: ['./property-values-group.component.scss'],
})
export class PropertyValuesGroupComponent implements OnInit, OnDestroy {
  @Input() group: ValuesGroupedByMaterialFamilyInterface;
  @Input() property: ArticlePropertyProperty;
  @Input() singlePropertyValuesColumn: boolean;
  @Input() propertyColumnContainsMultipleGroups: boolean;
  @Input() propertyContainer: HTMLElement;
  @Input() saleModeActive: boolean;

  @ViewChild('groupContainer') groupContainer: ElementRef;
  @ViewChild('infoPopover') infoPopover: NgbPopover;

  isSelected = false;
  popover: PopoverRef;
  compatibleWithSelectedGroup = false;
  allCompatiblePropertyValueGroupIsSelected = false;

  readonly id = UuidGenerator.uuid();
  private subscriptions: Subscription = new Subscription();

  constructor(
    private propertyValueGroupsService: PropertyValueGroupsService,
    private popoverService: PopoverService,
    private uncachepipe: UncachePipe,
    private infoPopoversService: InfoPopoversService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.propertyValueGroupsService.getSelectedValueGroupsDataAsObservable().subscribe((selectedValueGroups) => {
        this.isSelected = selectedValueGroups.some((valuesGroup) => valuesGroup.groupId === this.id);

        this.compatibleWithSelectedGroup = selectedValueGroups.some(
          (valuesGroup) =>
            valuesGroup.articleVariantNames.some((name) => this.group.articleVariantNames.includes(name)) &&
            valuesGroup.propertyId !== this.property.id
        );

        this.allCompatiblePropertyValueGroupIsSelected =
          typeof selectedValueGroups[0]?.articleVariantNames[0] === 'number' && selectedValueGroups[0]?.propertyId !== this.property.id;
      })
    );

    if (!this.infoPopoverDismissed) {
      this.subscriptions.add(
        this.infoPopoversService.getOpenedInfoPopoverAsObservable().subscribe((id) => {
          if (this.id === id) {
            this.infoPopover.open();

            return;
          }

          this.infoPopover?.close();
        })
      );
    }
  }

  infoPopperOptions = (options: Partial<Options>) => {
		for (const modifier of options.modifiers || []) {
			if (modifier.name === 'offset' && modifier.options) {
				modifier.options.offset = () => [0, this.calculateInfoPopoverOffset()];
			}
		}
		return options;
	};

  private calculateInfoPopoverOffset(): number {
    const propertyContainerRect = this.propertyContainer.getBoundingClientRect();
    const groupContainerRect = this.groupContainer.nativeElement.getBoundingClientRect();
    const additionalSpace = 25;

    return groupContainerRect.left - propertyContainerRect.left + additionalSpace;
  }

  onGroupSelect(): void {
    this.propertyValueGroupsService.selectPropertyValuesGroup(this.group, this.id, this.property.id);

    if (this.group.materialFamily && !this.infoPopoverDismissed) {
      this.infoPopoversService.setOpenedInfoPopover(this.id)
    }
  }

  getBackgroundColor(materialFamily: string | null): string[] {
    let bgClasses = this.saleModeActive ? ['hover-bg-warning-200'] : ['hover-bg-primary-200'];

    if (this.shouldBeHighlighted()) {
      bgClasses.push(this.saleModeActive ? 'bg-warning-200' : 'bg-primary-200');

      return bgClasses;
    }

    if (materialFamily) {
      bgClasses.push('bg-gray-200');
    }

    return bgClasses;
  }

  shouldBeHighlighted(): boolean {
    if (this.allCompatiblePropertyValueGroupIsSelected && (!this.singlePropertyValuesColumn || this.propertyColumnContainsMultipleGroups)) {
      return true;
    }

    return this.isSelected || this.compatibleWithSelectedGroup;
  }

  onOpenPopover(value: ArticlePropertyValueValueInterface, templateRef: TemplateRef<any>, origin: HTMLElement) {
    const description = [value.description];
    if (value.materialFamily) {
      description.push(`(${value.materialFamily})`);
    }

    const additionalOffset = 5;

    this.popover = this.popoverService.open<{ img: string; value: string }>(
      {
        content: templateRef,
        origin,
        width: '110px',
        height: '110px',
        data: { img: value.thumbnail, value: description.join(' ') },
      },
      additionalOffset
    );

    this.getUncachedImageBackground(value).subscribe((largeThumb) => {
      const img = new Image();
      img.onload = () => {
        this.popover.data.img = largeThumb;
      };
      img.src = largeThumb;
    });
  }

  onClosePopover() {
    this.popover.close();
  }

  private getUncachedImageBackground(
    { img_thumbnails, img }: ArticlePropertyValueValueInterface,
    size?: PropertyImageSizes
  ): Observable<string> {
    const value =
      (img_thumbnails &&
        ((size && img_thumbnails[size]) ||
          img_thumbnails[PropertyImageSizes.EXTRA_LARGE] ||
          img_thumbnails[PropertyImageSizes.LARGE] ||
          img_thumbnails[PropertyImageSizes.MEDIUM] ||
          img_thumbnails[PropertyImageSizes.SMALL])) ||
      img;
    return this.uncachepipe.transform(value);
  }

  onDismissInfoPopovers(): void {
    this.infoPopoversService.dismissInfoPopovers();
  }

  get infoPopoverDismissed(): boolean {
    const isDismissed = localStorage.getItem(LocalStorageKeys.COMPATIBILITY_INFO_POPOVER_DISMISSED);

    if (isDismissed) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
