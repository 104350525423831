import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefreshButtonModule } from './refresh-button/refresh-button.module';
import { CloseButtonModule } from './close-button/close-button.module';
import { RadioButtonModule } from './radio-button/radio-button.module';
import { CheckMarkModule } from './check-mark/check-mark.module';
import { TextFieldModule } from './text-field/text-field.module';
import { GenericModalModule } from './generic-modal/generic-modal.module';
import { TooltipModule } from './tooltip/tooltip.module';
import { OrderArticleMaterialInputModule } from './order-article-material-input/order-article-material-input.module';
import { DateInputModule } from './date-input/date-input.module';
import { CommunicationTabModule } from './communication-tab/communication-tab.module';
import { MaterialsListModule } from './materials-list/materials-list.module';
import { TimeInputModule } from './time-input/time-input.module';
import { OrderArticleSelectInputModule } from './order-article-select-input/order-article-select-input.module';

@NgModule({
  imports: [
    CommonModule,
    RefreshButtonModule,
    CloseButtonModule,
    RadioButtonModule,
    CheckMarkModule,
    TextFieldModule,
    GenericModalModule,
    TooltipModule,
    OrderArticleMaterialInputModule,
  ],
  exports: [
    RefreshButtonModule,
    CloseButtonModule,
    RadioButtonModule,
    CheckMarkModule,
    TextFieldModule,
    GenericModalModule,
    TooltipModule,
    OrderArticleMaterialInputModule,
    DateInputModule,
    CommunicationTabModule,
    MaterialsListModule,
    TimeInputModule,
    OrderArticleSelectInputModule,
  ],
  declarations: [],
})
export class UiElementsModule {}
