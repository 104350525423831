import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { SortInterface } from '../../../core/models/sort.model';
import { CacheableObservable } from '../../../cacheable-observable/cacheable-observable.model';
import { SortFactory } from '../../../core/factory/sort.factory';
import { ListMetaInterface } from '../../../shared/interfaces/listMeta.interface';
import { PriceRequestInterface, PriceRequestStatusEnum } from '../../../core/models/price-request.model';
import { Observable, map } from 'rxjs';
import { UserInterface } from '../../../core/models/user.model';
import { ListModeSwitchService } from '../../../shared/components/list-mode-switch/list-mode-switch.service';

export interface PriceRequestsListResponseInterface {
  meta: ListMetaInterface;
  data: PriceRequestInterface[];
}

export type PriceRequestCreationInterface = Pick<PriceRequestInterface, 'title'>;

export type PriceRequestUpdateInterface = Partial<Pick<PriceRequestInterface, 'title' | 'status'>>;

export interface PriceRequestResponseInterface {
  data: PriceRequestInterface;
  additional: any[];
}

export interface PriceRequestsFilterInterface {
  'statuses[]'?: PriceRequestStatusEnum[];
  'users[]'?: number[];
  page?: number;
}

export interface PriceRequestDeleteResponseInterface {
  success: boolean;
}

interface FilterAllPriceRequestsParamsInterface extends PriceRequestsFilterInterface {
  sort?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PriceRequestsService {
  constructor(
    private api: ApiService,
    private listModeSwitchService: ListModeSwitchService,
  ) {}

  filterAll(filter: PriceRequestsFilterInterface, sort?: SortInterface): CacheableObservable<PriceRequestsListResponseInterface> {
    const saleMode = this.listModeSwitchService.getSaleMode();

    let params: FilterAllPriceRequestsParamsInterface = filter;
    if (sort) {
      params = { ...params, sort: SortFactory.getString(sort) };
    }

    return this.api.get(`custom-made-price-requests`, { ...params, 'sale-mode': saleMode }) as CacheableObservable<PriceRequestsListResponseInterface>;
  }

  getOne(id: PriceRequestInterface['id']): CacheableObservable<PriceRequestInterface> {
    const saleMode = this.listModeSwitchService.getSaleMode();
    return this.api.get(`custom-made-price-requests/${id}`, { 'sale-mode': saleMode })
      .pipe(map(({ data }) => data)) as CacheableObservable<PriceRequestInterface>;
  }

  create(data: PriceRequestCreationInterface): CacheableObservable<PriceRequestResponseInterface> {
    return this.api.post('custom-made-price-requests/', data) as CacheableObservable<PriceRequestResponseInterface>;
  }

  update(id: PriceRequestInterface['id'], data: PriceRequestUpdateInterface): Observable<PriceRequestResponseInterface> {
    return this.api.patch(`custom-made-price-requests/${id}`, data);
  }

  delete(id: PriceRequestInterface['id']): Observable<PriceRequestDeleteResponseInterface> {
    return this.api.delete(`custom-made-price-requests/${id}`, null);
  }

  copy(id: PriceRequestInterface['id']): Observable<PriceRequestInterface> {
    return this.api.post(`custom-made-price-requests/${id}/copy`, null).pipe(map(({ data }) => data));
  }

  getUsers(): Observable<UserInterface[]> {
    return this.api.get('custom-made-price-requests/users').pipe(map(({ data }) => data));
  }
}
