import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddressFormComponent} from './address-form/address-form.component';
import {AddressFormService} from './address-form/address-form.service';
import {MissingTranslationHandler, TranslateModule} from '@ngx-translate/core';
import {CustomMissingTranslationHandler} from '../core/models/custom-missing-translation-handler.model';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {TextFieldModule} from '../ui-elements/text-field/text-field.module';
import { InlineFieldModule } from '../ui-elements/inline-field/inline-field.module';
import { SharedModule } from '../shared/shared.module';
import { LoaderModule } from '../ui-elements/loader/loader.module';
import { SearchModule } from '../search/search.module';
import { AddressItemModule } from './address-item/address-item.module';
import { AddressSelectModalComponent } from './address-select-modal/address-select-modal.component';
import { DeliveryRequirementsFormComponent } from './delivery-requirements-form/delivery-requirements-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiElementsModule } from '../ui-elements/ui-elements.module';
import { FixedDateTimeFormComponent } from './fixed-date-time-form/fixed-date-time-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler},
    }),
    TextFieldModule,
    InlineFieldModule,
    SharedModule,
    LoaderModule,
    SearchModule,
    AddressItemModule,
    NgbModule,
    UiElementsModule,
  ],
  declarations: [
    AddressFormComponent,
    AddressSelectModalComponent,
    DeliveryRequirementsFormComponent,
    FixedDateTimeFormComponent,
  ],
  exports: [
    AddressFormComponent,
    AddressSelectModalComponent,
    DeliveryRequirementsFormComponent,
    FixedDateTimeFormComponent,
  ],
  providers: [
    AddressFormService
  ]
})
export class AddressModule {}
