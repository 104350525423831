<div class="d-flex flex-column flex-wrap p-4">
  <div class="pb-4" *ngFor="let downloadBlock of downloadBlocks">
    <h4>{{ downloadBlock.name }}</h4>
    <div class="d-flex flex-column flex-wrap">
      <a *ngFor="let file of downloadBlock.files" [href]="file.url" download class="btn btn-link d-flex align-items-center">
        {{file.filename}} {{file.name | fileExtension | uppercase}}
        <i class="icon ni-download ms-2"></i>
      </a>
    </div>
  </div>
</div>
