<ul class="list list--groups list-unstyled">
  <li class="item-row d-flex flex-wrap align-items-center position-relative cursor-pointer py-2 px-3
             border-bottom border-color-gray-400 small color-black bg-white hover-bg-white"
  >
    <div class="d-flex align-items-center flex-grow-1">
      <button class="btn btn-default btn-sm p-1 me-1 color-black" (click)="onGoBack()" appClickStopPropagation>
        <i class="icon ni-chevron-left d-block"></i>
      </button>
      <div class="text-break ps-2">{{ openedOrder?.title }}</div>
    </div>

    <div class="ms-auto">
      <button
        *ngIf="selectedGroup || (!selectedOrder || selectedOrder?.id !== openedOrder?.id)"
        class="btn btn-light btn-sm"
        (click)="onSelectOrder()"
        [ngbTooltip]="'ORDER_SELECT.GROUPS.TOOLTIP' | translate"
        triggers="mouseenter:mouseleave"
        container="body"
        data-select-order
      >
        {{ 'ORDER_SELECT.GROUPS.SELECT' | translate }}
      </button>
      <i *ngIf="!selectedGroup && selectedOrder && selectedOrder?.id === openedOrder?.id" class="icon ni-check text-primary"></i>
    </div>
  </li>
  <li class="item-row d-flex align-items-center position-relative cursor-pointer py-2 px-3 border-bottom border-color-gray-400">
    <button class="btn btn-primary btn-sm d-flex mx-1 my-2" (click)="onNewGroupFormToggle()" data-create-new-group>
      <i class="icon d-block me-2 ni-folder-add"></i>
      <div>{{ "ORDER_SELECT.GROUPS.NEW_GROUP" | translate }}</div>
    </button>
  </li>
  <li *ngIf="isNewGroupFormActive" class="item-row d-flex align-items-center position-relative cursor-pointer
      px-3 border-bottom border-color-gray-400 bg-gray-100"
  >
    <app-selection-menu-new-item
      class="w-100"
      (create)="onCreateNewGroup($event)"
      [type]="newItemType.GROUP"
    ></app-selection-menu-new-item>
  </li>

  <app-loader #loader></app-loader>
  <ng-container *ngIf="!loader.loading && groups.length">
    <ng-container *ngTemplateOutlet="groupList; context: {$implicit: groups}"></ng-container>
  </ng-container>
</ul>

<ng-template #groupList let-groups>
  <ng-container *ngFor="let child of groups">
    <ng-container *ngTemplateOutlet="groupItem; context: {$implicit: child}">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #groupItem let-group>
  <li
    class="item-row position-relative cursor-pointer py-2 px-3 border-bottom border-color-gray-400 small color-black"
    [class.bg-gray-100]="groupIsInList(group, activeGroups)"
    (click)="onToggleGroupOpenState(group)"
  >
    <div class="caption-row d-flex align-items-center" [class.ps-3]="group.groupLevel > 1">
      <i class="icon ni-folder me-2 my-1 d-block color-black" [class.ms-3]="group.groupLevel"></i>
      <div class="me-2 text-nowrap text-truncate flex-grow-1">{{ group.title }}</div>
      <button 
        *ngIf="!isGroupSelected(group)" 
        class="btn btn-light btn-sm" 
        (click)="$event.stopPropagation();onSelectGroup(group)" 
        data-select-group
      >
        {{ 'ORDER_SELECT.GROUPS.SELECT' | translate }}
      </button>
      <i *ngIf="isGroupSelected(group)" class="icon ni-check text-primary"></i>
    </div>
  </li>
  <li
    *ngIf="!isNewSubGroupFormActive && groupIsInList(group, openGroups) && groupIsInList(group, activeGroups) && group.groupLevel < maxGroupLevel"
    class="py-2 px-3 border-bottom border-color-gray-400"
    [class.bg-gray-100]="groupIsInList(group, activeGroups)"
    [class.ps-4]="group.groupLevel"
  >
    <button class="btn btn-primary btn-sm d-flex mx-3" (click)="onNewSubGroupFormToggle()" [class.ms-4]="group.groupLevel">
      <i class="icon d-block me-2 ni-folder-add"></i>
      <div>{{ "ORDER_SELECT.GROUPS.NEW_SUBGROUP" | translate }}</div>
    </button>
  </li>
  <li
    *ngIf="groupIsInList(group, openGroups) && groupIsInList(group, activeGroups) && group.groupLevel < maxGroupLevel && isNewSubGroupFormActive"
    class="item-row d-flex align-items-center position-relative cursor-pointer
    ps-3 pe-2 me-1 border-bottom border-color-gray-400 bg-gray-100"
  >
    <app-selection-menu-new-item
      class="w-100 ms-3"
      [class.ps-3]="group.groupLevel"
      (create)="onCreateNewGroup($event, group)"
      [type]="newItemType.SUBGROUP"
    ></app-selection-menu-new-item>
  </li>
  <ng-container *ngIf="groupIsInList(group, openGroups) && group.children.length">
    <ng-container *ngTemplateOutlet="groupList; context: {$implicit: group.children}"></ng-container>
  </ng-container>
</ng-template>
