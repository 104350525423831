import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ExtraItemsService } from '../extra-row/extra-items.service';
import { SelectedRowsService } from '../../../services/selected-rows/selected-rows.service';
import { OrderArticlesListRow } from '../../../order-articles-list.interface';
import { OpenGroupsService } from './open-groups/open-groups.service';
import { UserService } from '../../../../../core/services/user/user.service';
import { RemoveRowInterface } from "../../../../can-modify-product-view.component";
import { OrderArticlesRowsService } from '../../../services/order-articles-rows/order-articles-rows.service';
import { ExtraRowComponentModel } from '../../models/extra-row-component.model';
import { DraggingDetectorService } from '../../../draggable/services/dragging-detector/dragging-detector.service';
import { FieldClass } from '../../../../../core/enums/field-class';
import { FieldService } from '../../../../../core/services/field/field.service';
import { FieldType } from '../../../../../shared/directives/hide-by-field-type/field-types.enum';
import { ListModeSwitchService } from '../../../../../shared/components/list-mode-switch/list-mode-switch.service';
import { OrderState } from '../../../../../core/enums/order.state.enum';
import { OrderArticleType } from '../../../../../core/models/order-article.model';
import { PriceRequestItemStatusEnum } from '../../../../../core/models/price-request-item.model';
import FeatureFlags from '../../../../../../../featureFlags.json';

export interface UngroupEventInterface {
  group: RemoveRowInterface;
  children: OrderArticlesListRow[];
}

@Component({
  selector: 'app-order-articles-list-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent extends ExtraRowComponentModel implements OnInit {
  @Input() orderState?: OrderState;

  private mouseEnterTimeout: NodeJS.Timer;
  dragging: boolean;
  isDiscountFieldVisible: boolean;

  constructor(
    protected fb: UntypedFormBuilder,
    protected extraListElementService: ExtraItemsService,
    protected selectedRowsService: SelectedRowsService,
    private openGroupsService: OpenGroupsService,
    protected listModeSwitchService: ListModeSwitchService,
    protected userService: UserService,
    protected orderArticlesRowsService: OrderArticlesRowsService,
    protected cdr: ChangeDetectorRef,
    private draggingDetectorService: DraggingDetectorService,
    private fieldService: FieldService
  ) {
    super(fb, extraListElementService, selectedRowsService, listModeSwitchService, orderArticlesRowsService, cdr, userService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.add(
      this.openGroupsService.getOpenGroupsAsObservable().subscribe(() => {
        this.row.isOpen = this.openGroupsService.isOpen(this.row);
      })
    );
    this.subscriptions.add(
      this.draggingDetectorService.getStatusAsObservable().subscribe((status) => {
        this.dragging = status;
      })
    );
    this.subscriptions.add(
      this.fieldService.getOptionsAsObservable(FieldClass.ORDER).subscribe((fields) => {
        this.isDiscountFieldVisible = fields
          .reduce((acc, val) => acc.concat(val.values), [])
          .some((value) => value.checked && FieldType.DISCOUNT.includes(value.name));
      })
    );
  }

  shouldDisplayWarningIcon(row: OrderArticlesListRow = this.row): boolean {
    // Configuration updates exist (Migrations)
    if ('versionOutdated' in row && 'migrationStatus' in row && (row.versionOutdated || row.migrationStatus)) {
      return true;
    }

    if (!FeatureFlags.oldNonStandardsSupported) {
      // Expired old NS
      if (row.type === OrderArticleType.CUSTOM_ITEM_TYPE && this.orderState === OrderState.DRAFT && this.userService.isDealer()) {
        return true;
      }
      
      if ('customMadePriceRequestItem' in row && row.customMadePriceRequestItem) {
        // Needs clarification
        if (row.customMadePriceRequestItem.clarificationQuestion && !row.customMadePriceRequestItem.clarificationAnswer) {
          return true;
        }

        // PM - custom-made doesn't have code
        if (!row.customMadePriceRequestItem.code && this.userService.isPM()) {
          return true;
        }

        // Canceled custom-made
        if (row.customMadePriceRequestItem.status === PriceRequestItemStatusEnum.CANCELED) {
          return true;
        }
      }
    }

    if (row.children?.length) {
      return row.children.some((child) => this.shouldDisplayWarningIcon(child));
    }

    return false;
  }

  onToggleGroup() {
    this.openGroupsService.toggle(this.row);
  }

  onMouseEnter() {
    this.mouseEnterTimeout = setTimeout(() => this.openGroupsService.open(this.row), 500);
  }

  onMouseLeave() {
    clearTimeout(this.mouseEnterTimeout);
  }

  onGroupSelect(selected: boolean) {
    this.selectedRowsService.onGroupSelect(this.row, selected);
  }
}
