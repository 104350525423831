import { Injectable } from '@angular/core';
import {
  OrderItemsPasteEventType,
  PasteEvent,
  PasteItemInterface,
  ClipboardRowInterface,
  ClipboardPartialOrderArticleInterface,
  ClipboardPartialOriginalsInterface,
  ClipboardOrderArticlePartialItemInterface,
  ClipboardCopyMapInterface,
} from './order-items-clipboard.interface';
import { ExtraListElementRowInterface } from '../order-articles-list/order-articles-list/components/extra-row/extra-items.model';
import {
  OrderArticlesListRow,
  OrderArticlesListRowItemInterface,
  pageBreakKey,
  RowTypes
} from '../order-articles-list/order-articles-list.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { OrderItemType, OrderPasteActionType } from './order-item-type.enum';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericModalTypes } from '../../ui-elements/generic-modal/generic-modal-types';
import { TranslateService } from '@ngx-translate/core';
import { OrderArticleInterface, OrderArticleType } from '../../core/models/order-article.model';
import { UserService } from '../../core/services/user/user.service';
import { RoleInterface } from '../../core/models/user.model';
import { UserRole } from '../../core/enums/user-role.enum';
import { OrderArticleSaleCoefficientType, OrderArticleDiscountType } from '../../core/enums/order-article-discount-type.enum';
import { OrdersService } from '../orders.service';
import { FullOrderArticleInterface } from '../../core/models/full-order-article.model';
import { flattenDeep } from "../../core/util/flatten-deep/flatten-deep.helper";
import { OrderInterface } from '../../core/models/order.model';
import { OkCancelModalComponent } from '../../ui-elements/generic-modal/ok-cancel-modal/ok-cancel-modal.component';
import { ListModeSwitchService } from '../../shared/components/list-mode-switch/list-mode-switch.service';

function transformForPaste(selectedRows: ClipboardRowInterface[]): ClipboardRowInterface[] {
  const pasteCopy: ClipboardRowInterface[] = [];

  if (!selectedRows || !selectedRows.length) {
    return pasteCopy;
  }

  selectedRows.forEach(row => {
    const r: ClipboardRowInterface = {
      id: row.id,
      pageBreak: { id: row.pageBreak?.id ?? row.parent?.id ?? null },
      parent: { id: row.parent?.id ?? null },
      position: row.position,
      rowType: row.rowType,
      type: row.rowType === RowTypes.PRODUCT ? OrderItemType.ORDER_ARTICLE : OrderItemType.PAGE_BREAK,
      orderArticleType: row.orderArticleType,
      order: row.order,
      discountType: row.discountType,
      saleDiscount: row.saleDiscount,
      salePrice: row.salePrice,
      additionalParts: row.additionalParts,
      item: row.item,
      size: row.size,
    };

    pasteCopy.push(r);

    if (row.children?.length) {
      pasteCopy.push(...transformForPaste(row.children));
    }
  });

  return pasteCopy;
}

function transformToAction(order: number, selectedRows: OrderArticlesListRow[]): ClipboardRowInterface[] {
  const resultsArray: ClipboardRowInterface[] = [];

  if (!selectedRows || !selectedRows.length) {
    return resultsArray;
  }

  const copyMap: ClipboardCopyMapInterface = {};
  selectedRows.forEach(row => {
    const additionalParts: ClipboardPartialOrderArticleInterface[] = [];

    const groupId = row.pageBreak?.id ?? row.parent?.id ?? null;
    if (!copyMap[groupId]) {
      copyMap[groupId] = [];
    }

    let clipboardRow: ClipboardRowInterface;

    if ('originals' in row) { // in case of OrderArticlesListRowInterface
      row.additionalParts?.forEach(additionalPart => {
        additionalParts.push(transformAdditionalItemForClipboard(additionalPart));
      });

      clipboardRow = {
        id: row.id,
        order: order,
        rowType: row.rowType,
        title: row.title,
        orderArticleType: row.type,
        originals: transformOriginalsForClipboard(row.originals),
        groupLevel: row.groupLevel,
        item: {...transformItemForClipboard(row.item)},
        additionalParts,
        position: row.position || 0,
        pageBreak: { id: row.pageBreak?.id ?? row.parent?.id ?? null },
        parent: { id: row.parent?.id ?? null },
        discountType: row.discountType,
        saleDiscount: row.saleDiscount,
        salePrice: row.salePrice,
        discount: row.discount,
        customPrice: row.customPrice,
        pricelistPrice: row.pricelistPrice,
        saleCoefficientType: row.saleCoefficientType,
        customMadePriceRequestItem: row.customMadePriceRequestItem
      };
    } else { // in case of ExtraListElementRowInterface
      clipboardRow = {
        id: row.id,
        order: order,
        rowType: row.rowType,
        orderArticleType: row.type,
        title: row.title,
        groupLevel: row.groupLevel,
        item: {...transformItemForClipboard(row.item)},
        additionalParts,
        position: row.position || 0,
        pageBreak: { id: row.pageBreak?.id ?? row.parent?.id ?? null },
        parent: { id: row.parent?.id ?? null },
      };
    }

    copyMap[groupId].push(clipboardRow);
  });

  // after grouping items we need to reconstruct tree of items for clipboard preview
  // if item has no parent it will be considered as a root level item and will live one 'null' key in copyMap object
  // but in some cases there will be no 'null' for example copying only subgroup
  // so here we need to find the lowest possible object key from copyMap
  let oldestFromList = 'null';
  if (copyMap['null'] === undefined ) {
    oldestFromList = Math.min(...Object.keys(copyMap).map(i => +i)).toString();
  }

  resultsArray.push(...JSON.parse(JSON.stringify(copyMap[oldestFromList])));
  assignChildrenFromMap(resultsArray, copyMap);

  // try to map other items also if they still exist
  // used items are removed from copyMap after use, so if there is left something - we need to add it
  Object.keys(copyMap).forEach(group => {
    if (group === oldestFromList) {
      return;
    }
    resultsArray.push(...JSON.parse(JSON.stringify(copyMap[group])));
    assignChildrenFromMap(resultsArray, copyMap);
  });

  return resultsArray;
}

function assignChildrenFromMap(resultsArray: ClipboardRowInterface[], mapOfCopies: ClipboardCopyMapInterface) {
  resultsArray.forEach(r => {
    if (mapOfCopies[r.id]) {
      r.children = mapOfCopies[r.id];
      // drop already remapped items to help understand if we still have something unmapped
      delete mapOfCopies[r.id];

      if (r.children.length) {
        assignChildrenFromMap(r.children, mapOfCopies)
      }
    }
  });
}

function transformOriginalsForClipboard(originals: FullOrderArticleInterface): ClipboardPartialOriginalsInterface | null {
  if (!originals?.orderArticle?.code) {
    return null;
  }

  return { orderArticle: { code: originals.orderArticle.code, children: [] }};
}

function transformAdditionalItemForClipboard(item: OrderArticleInterface): ClipboardPartialOrderArticleInterface | null {
  if (!item) {
    return null;
  }

  const part: ClipboardPartialOrderArticleInterface = {
    id: item.id,
    title: item.title,
    translations: item.translations,
    discountType: item.discountType,
    discount: item.discount,
    customPrice: item.customPrice,
    pricelistPrice: item.pricelistPrice,
    saleCoefficientType: item.saleCoefficientType,
    saleDiscount: item.saleDiscount,
    fullCode: item.fullCode,
    orderArticleType: item.type,
  };

  return part;
}

function transformItemForClipboard(item: OrderArticlesListRowItemInterface): ClipboardOrderArticlePartialItemInterface | null {
  if (!item) {
    return null;
  }

  const part: ClipboardOrderArticlePartialItemInterface = {
    img: item.img,
    system: item.system,
    title: item.title,
    category: item.category,
    code: item.code,
    translations: item.translations,
  };

  return part;
}

function getLastPosition(rows: OrderArticlesListRow[]): number {
  return Math.max.apply(
    Math,
    rows.map(row => row.position)
  );
}

function transformToUpdate(
  pasteItems: PasteItemInterface[],
  selectedRows: OrderArticlesListRow[],
  allRows: OrderArticlesListRow[],
  event: OrderItemsPasteEventType
): PasteEvent {
  const groups = pasteItems.filter(item => item.rowType === RowTypes.GROUP);

  let { firstSelectedItem, lastSelectedItem } = { firstSelectedItem: null, lastSelectedItem: null };
  let group: ExtraListElementRowInterface = null;
  if (selectedRows?.length) {
    const sortedSelectedRows = selectedRows.sort((a, b) => a.groupLevel - b.groupLevel);
    [firstSelectedItem] = sortedSelectedRows;
    lastSelectedItem = sortedSelectedRows[sortedSelectedRows.length - 1];
    if (firstSelectedItem.rowType === RowTypes.GROUP) {
      group = firstSelectedItem;
    } else {
      const pageBreak = firstSelectedItem[pageBreakKey(firstSelectedItem)];
      if (pageBreak) {
        group = pageBreak;
      }
    }
  }

  if (pasteItems && pasteItems.length) {
    let pos = 0;
    if (lastSelectedItem) {
      if (!group) {
        const key = pageBreakKey(lastSelectedItem);
        if (lastSelectedItem[key]) {
          group = lastSelectedItem[key];
        }
      }
      pos = lastSelectedItem.position + 1;
    }
    let iterator = 0;
    let firstPosition = -1;
    pasteItems = pasteItems.map(({ id, rowType, position, pageBreak, parent }) => {
      let newPosition = 0;
      if (lastSelectedItem && !group) {
        newPosition = pos;
      } else if (group) {
        group.children = allRows.find(row => row.id === group.id).children;
        newPosition =
          group.children?.length && group.children.length + 1 === selectedRows.length
            ? getLastPosition(group.children) + 1
            : pos;
      } else {
        newPosition = allRows?.length
          ? getLastPosition(allRows as OrderArticlesListRow[]) + 1
          : position;
      }
      newPosition += iterator++;
      if (firstPosition < 0) {
        firstPosition = newPosition;
      }

      let updatedPageBreak;
      if (rowType === RowTypes.GROUP) {
        updatedPageBreak =
          parent && groups.find(g => g.id === parent.id)
            ? parent.id
            : null;
      } else {
        updatedPageBreak =
          pageBreak && groups.find(g => g.id === pageBreak.id)
            ? pageBreak.id
            : null;
      }

      return {
        id,
        position: newPosition,
        type: rowType === RowTypes.PRODUCT ? OrderItemType.ORDER_ARTICLE : OrderItemType.PAGE_BREAK,
        pageBreak: updatedPageBreak || (group ? group.id : null),
      };
    });

    if (lastSelectedItem) {
      // Move other rows +1 position if they have position lower or equal to new pasted item position
      let foundRows = allRows.filter(row => row.position >= firstPosition);
      if (event === OrderItemsPasteEventType.CUT) {
        foundRows = foundRows.filter(row => pasteItems.findIndex(found => found.id === row.id) < 0);
      }
      if (foundRows && foundRows.length) {
        foundRows.map(row => {
          row.position += pasteItems.length;
          return row;
        });
      }
    }

    if (event === OrderItemsPasteEventType.CUT) {
      allRows = allRows.filter(row => pasteItems.findIndex(found => found.id === row.id) < 0);
    }
    return { movedRows: allRows, pasteItems };
  }
}

const CLIPBOARD_LOCAL_STORAGE_KEY = 'clipboard';
const CLIPBOARD_ACTION_LOCAL_STORAGE_KEY = 'clipboardAction';

@Injectable({
  providedIn: 'root',
})
export class OrderItemsClipboardService {
  private clipboardItems$: BehaviorSubject<ClipboardRowInterface[]> = new BehaviorSubject<ClipboardRowInterface[]>([]);
  private clipboardItems: ClipboardRowInterface[] = [];
  private clipboardAction: OrderPasteActionType = OrderPasteActionType.COPY;
  private clipboardItemsForPaste: ClipboardRowInterface[] = [];
  private userRole: RoleInterface;

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private userService: UserService,
    protected ordersService: OrdersService,
    private listModeSwitchService: ListModeSwitchService
  ) {
    this.userService.getUser().subscribe(user => {
      if (user) {
        this.userRole = user.role;
      }
    });

    if (this.hasClipboardInLocalStorage()) {
      this.setClipboard(this.getLocalStorageClipboard(), this.getLocalStorageClipboardAction());

      this.clipboardItemsForPaste = transformForPaste(this.clipboardItems);
    }
  }

  copy(orderId: OrderInterface['id'], selected: OrderArticlesListRow[]) {
    this.clipboardAction = OrderPasteActionType.COPY;
    this.renewClipboard(transformToAction(orderId, selected), this.clipboardAction);
    this.clipboardItemsForPaste = transformForPaste(this.clipboardItems);
  }

  cut(orderId: OrderInterface['id'], selected: OrderArticlesListRow[]) {
    this.clipboardAction = OrderPasteActionType.CUT;
    this.renewClipboard(transformToAction(orderId, selected), this.clipboardAction);
    this.clipboardItemsForPaste = transformForPaste(this.clipboardItems);
  }

  paste(
    orderId: OrderInterface['id'],
    selectedRows?: OrderArticlesListRow[],
    allRows?: OrderArticlesListRow[],
    orderFullCodes?: string[],
    orderArticlesInOrder?: FullOrderArticleInterface[]
  ): Observable<any> {
    const pasteItemsAndUpdateOtherRows = (items, event: OrderItemsPasteEventType, path: string, discount: boolean = false) => {
      const flattenAllRows = flattenDeep(allRows);
      const pasteEvent: PasteEvent = transformToUpdate(items, selectedRows, flattenAllRows, event);
      const tempRows = pasteEvent.movedRows;
      const saleMode = this.listModeSwitchService.getSaleMode();

      return this.api.post(path, pasteEvent.pasteItems, { discount, 'sale-mode': saleMode }).pipe(
        map(({ data }) => {
          return { orderArticles: data.OrderArticle || [], pageBreaks: data.PageBreak, event, modifiedRows: tempRows };
        })
      );
    };

    const differentOrder = this.clipboardItemsForPaste.some(item => item.order && item.order !== orderId);

    let observable: Observable<any>;
    let observableWithDiscount: Observable<any>;

    if (this.clipboardItemsForPaste && this.clipboardItemsForPaste.length) {
      switch (this.clipboardAction) {
        case OrderPasteActionType.COPY:
          observable = pasteItemsAndUpdateOtherRows(this.clipboardItemsForPaste, OrderItemsPasteEventType.COPY, `order-articles/copy/${orderId}`, false);
          observableWithDiscount = pasteItemsAndUpdateOtherRows(
            this.clipboardItemsForPaste,
            OrderItemsPasteEventType.COPY,
            `order-articles/copy/${orderId}`,
            true
          );
        break;
        case OrderPasteActionType.CUT:
          observable = pasteItemsAndUpdateOtherRows(this.clipboardItemsForPaste, OrderItemsPasteEventType.CUT, `order-articles/cut/${orderId}`, false);
          observableWithDiscount = pasteItemsAndUpdateOtherRows(this.clipboardItemsForPaste, OrderItemsPasteEventType.CUT, `order-articles/cut/${orderId}`, true);
        break;
      }
    }

    const discountExists = this.getDiscountsForCopy(this.clipboardItemsForPaste).length;

    if (differentOrder && discountExists) {
      if (
        !this.itemsHasDifferences(orderArticlesInOrder, this.clipboardItemsForPaste) &&
        this.itemsAlreadyInOrder(orderFullCodes, this.clipboardItemsForPaste)
      ) {
        // make silent copy without dialogs
        return observable;
      }

      // ask for user input about discount copying
      return new Observable(observer => {
        return this.translateService
          .get([
            'ORDER_PRODUCTS_LIST.MODAL.NO_DISCOUNT',
            'ORDER_PRODUCTS_LIST.MODAL.WITH_DISCOUNT',
            'ORDER_PRODUCTS_LIST.MODAL.HEADER',
            'ORDER_PRODUCTS_LIST.MODAL.BODY',
          ])
          .subscribe((translations: {[key: string]: string}) => {
            const [ok, optionalControl, headerContent, bodyContent] = Object.values(translations);
            const modalRef = this.modalService.open(OkCancelModalComponent, {
              windowClass: GenericModalTypes.GREY,
              size: 'lg',
              centered: true,
            });

            const componentInstance = modalRef.componentInstance as OkCancelModalComponent;
            componentInstance.closeable = true;
            componentInstance.buttonTexts = [optionalControl, ok];

            componentInstance.bodyContent = bodyContent;
            componentInstance.headerContent = headerContent;

            componentInstance.cancel.subscribe(() => {
              observableWithDiscount.subscribe(result => {
                observer.next(result);
                observer.complete();
              });
            });

            componentInstance.ok.subscribe(() => {
              observable.subscribe(result => {
                observer.next(result);
                observer.complete();
              });
            });

            modalRef.dismissed.subscribe(() => {
              observer.next({
                event: OrderItemsPasteEventType.CANCEL,
              });
              observer.complete();
            });
          });
      });
    }

    return discountExists ? observableWithDiscount : observable;
  }

  itemsAlreadyInOrder(codesList: string[], items: ClipboardRowInterface[]): boolean {
    const res = items.filter(item => {
      if (item.additionalParts) {
        return item.additionalParts.filter(aPart => codesList.indexOf(aPart.fullCode) !== -1).length > 0;
      } else {
        return codesList.indexOf(item.code) !== -1;
      }
    });

    return !!res?.length;
  }

  itemsHasDifferences(articles: FullOrderArticleInterface[], pastedItems: ClipboardRowInterface[]) {
    let result = false;
    for (let i = 0; i < pastedItems.length; i++) {
      if (!pastedItems[i].additionalParts) {
        continue;
      }

      for (let j = 0; j < pastedItems[i].additionalParts.length; j++) {
        // filter existing articles by full code and compare discounts
        const filteredArticle = articles.filter(item => {
          return item.orderArticle.fullCode === pastedItems[i].additionalParts[j].fullCode;
        });

        if (!filteredArticle || !filteredArticle.length) {
          // skip if nothing is found
          continue;
        }

        // all articles in order with the same code will have same prices and discounts
        // so we can use only first one for comparison
        // we will compare against few criteria:
        // - customPrice
        // - discount
        // - discountType
        // - saleCoefficientType
        // - saleDiscount
        result = this.itemAndChildrenHasDifferences(filteredArticle[0].orderArticle, pastedItems[i].additionalParts[j]);
        if (result) {
          return result;
        }
      }
    }

    return result;
  }

  itemAndChildrenHasDifferences(existing: OrderArticleInterface, pasted: ClipboardPartialOrderArticleInterface) {
    let result = false;
    switch (this.userRole.name) {
      case UserRole.ROLE_PM_NARBUTAS:
        result =
          existing.customPrice !== pasted.customPrice ||
          existing.discountType !== pasted.discountType ||
          existing.discount !== pasted.discount;
        break;
      case UserRole.ROLE_PM:
      case UserRole.ROLE_PM_RU:
        result = existing.discountType !== pasted.discountType || existing.discount !== pasted.discount;
        break;
      case UserRole.ROLE_DEALER:
        result = existing.saleCoefficientType !== pasted.saleCoefficientType || existing.saleDiscount !== pasted.saleDiscount;
        break;
    }

    if (!result && existing.children && existing.children.length && pasted.children && pasted.children.length) {
      for (let i = 0; i < pasted.children.length; i++) {
        if (this.itemAndChildrenHasDifferences(existing.children[i], pasted.children[i])) {
          result = true;
          break;
        }
      }
    }

    return result;
  }

  getDiscountsForCopy(items: ClipboardRowInterface[]) {
    if (!items.length) {
      return [];
    }

    return items.filter(item => {
      switch (this.userRole.name) {
        case UserRole.ROLE_DEALER:
          if (item.additionalParts && item.additionalParts.length) {
            const filteredItems = item.additionalParts.filter(aPart => {
              if (aPart.saleDiscount !== 0) {
                return true;
              }

              // NS/OS articles has custom coefficient with value 1, always
              if ([OrderArticleType.CUSTOM_ITEM_TYPE, OrderArticleType.OTHER_SUPPLIER_ITEM].includes(aPart.orderArticleType)) {
                return false;
              }

              if (aPart.saleCoefficientType !== OrderArticleSaleCoefficientType.DEFAULT) {
                return true;
              }
            });

            if (filteredItems && filteredItems.length) {
              return true;
            }
          }
          break;
        default:
          if (item.additionalParts && item.additionalParts.length) {
            const filteredItems = item.additionalParts.filter(aPart => {
              if (aPart.discountType === OrderArticleDiscountType.CUSTOM && aPart.discount > 0) {
                return true;
              }

              if (aPart.customPrice) {
                // OS/NS items' customPrice is purchase price, so it should not be treated as discount
                if ([OrderArticleType.CUSTOM_ITEM_TYPE, OrderArticleType.OTHER_SUPPLIER_ITEM].includes(aPart.orderArticleType)) {
                  return false;
                }

                // PM Narbutas though can set custom purchase price, which is treated like "discount"
                // the same way custom sale coefficient is "discount" for a dealer
                if (aPart.customPrice && aPart.customPrice !== aPart.pricelistPrice) {
                  return true;
                }
              }

              return false;
            });

            if (filteredItems && filteredItems.length) {
              return true;
            }
          }
          break;
      }

      return false;
    });
  }

  getClipboardItemsAsObservable(): Observable<ClipboardRowInterface[]> {
    return this.clipboardItems$.asObservable();
  }

  countClipboardItems(list: ClipboardRowInterface[]): number {
    let sum = 0;
    list?.forEach(element => {
      sum++;

      if (element.children && element.children.length) {
        sum += this.countClipboardItems(element.children);
      }
    })

    return sum;
  }

  getClipboardItems(): ClipboardRowInterface[] {
    return this.clipboardItems;
  }

  getClipboardAction(): OrderPasteActionType {
    return this.clipboardAction;
  }

  clearClipboard() {
    this.clipboardItems = [];
    this.clipboardItems$.next([]);
    this.clipboardAction = OrderPasteActionType.COPY;
    this.clearLocalStorage();
  }

  private setClipboard(items: ClipboardRowInterface[], action: OrderPasteActionType) {
    this.clipboardAction = action;

    this.clipboardItems = items;
    this.clipboardItems$.next(this.clipboardItems);

  }

  private renewClipboard(items: ClipboardRowInterface[], action: OrderPasteActionType) {
    this.setClipboard(items, action);
    this.saveToLocalStorage(items, action);
  }

  private hasClipboardInLocalStorage(): boolean {
    return !!localStorage.getItem(CLIPBOARD_LOCAL_STORAGE_KEY);
  }

  private saveToLocalStorage(items: ClipboardRowInterface[], action: OrderPasteActionType) {
    localStorage.setItem(CLIPBOARD_ACTION_LOCAL_STORAGE_KEY, JSON.stringify(action));
    localStorage.setItem(CLIPBOARD_LOCAL_STORAGE_KEY, JSON.stringify(items));
  }

  private getLocalStorageClipboard(): ClipboardRowInterface[] {
    return JSON.parse(localStorage.getItem(CLIPBOARD_LOCAL_STORAGE_KEY));
  }

  private getLocalStorageClipboardAction(): OrderPasteActionType {
    return JSON.parse(localStorage.getItem(CLIPBOARD_ACTION_LOCAL_STORAGE_KEY));
  }

  private clearLocalStorage() {
    localStorage.removeItem(CLIPBOARD_ACTION_LOCAL_STORAGE_KEY);
    localStorage.removeItem(CLIPBOARD_LOCAL_STORAGE_KEY);
  }
}
