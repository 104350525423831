import { Observable } from 'rxjs';
import { ModelViewerErrorEnum } from './model-viewer.enum';

export class ModelViewer {
  public viewer;
  private connected = false;

  constructor(
    private server, 
    private session, 
    private item,
    options: { assetsPath?: string; viewerElement: any },
    handleViewerError: (error: ModelViewerErrorEnum) => void,
  ) {
    this.viewer = new ViewerDependencies.egr.ocf.Viewer(options.assetsPath || '', options.viewerElement, handleViewerError);
  }

  updateGeometry() {
    return new Observable(observer => {
      this.viewer
        .updateGeometry()
        .then(() => {
          window.console.debug('article updated', { article: this.viewer.mArticle });
        })
        .then(() => {
          observer.next(true);
          observer.complete();
        })
        .catch(e => {
          console.log('Renderer: error updating geometry', e);
          observer.error(e);
        });
    });
  }

  createGeometry(item?) {
    item = item || this.item;

    return new Observable(observer => {
      this.viewer
        .createArticleById(item)
        .then(() => {
          window.console.debug('article created', { article: this.viewer.mArticle });
        })
        .then(() => {
          /**
           * @NOTE: If uncommented, this line causes three or more additional requests to the configurator, which, at first sight
           * looks like the unneeded ones. If any issues faced with 3D viewer - try uncommenting this part of code.
           */
          // this
          //   .viewer
          //   .createScreenshot(10, 10)
          //   .then(uri => {
          observer.next(true);
          observer.complete();
          // });
        })
        .catch(e => {
          window.console.error(e);
          observer.error(e);
        });
    });
  }

  connect() {
    if (!this.connected) {
      this.connected = this.viewer.connectEAIWSSession(this.server, this.session);
    }

    return this.connected;
  }

  stopRendering() {
    this.viewer.stopRendering();
  }

  resizeViewer() {
    this.viewer.resizeWebglViewer();
  }
}
