<app-generic-modal bodyClasses="pb-2 pt-4 px-4" centered>
  <ng-template appModalHeader>
    <h2 class="mb-0">{{ 'SETTINGS.SHIPPING_ADDRESSES.EDIT.TITLE' | translate}}</h2>
  </ng-template>
  <ng-template appModalBody>
    <app-loader [loading]="!countries" #loader [cssClasses]="'loader--white'"></app-loader>
    <app-address-form class="form"
      *ngIf="countries"
      (changed)="onChanged($event)"
      (init)="onChanged($event)"
      [model]="address"
      [countries]="countries"
      [editing]="true"
      [availableFields]="formFieldSettings"
    ></app-address-form>
  </ng-template>
  <ng-template appModalFooter>
    <button *ngFor="let button of buttons"
            (click)="button.action()"
            [disabled]="button.disabled"
            class="btn btn-lg rounded-0 mw-11r w-50"
            [ngClass]="button.classes">
      {{ button.text | translate }}
    </button>
  </ng-template>
</app-generic-modal>
