<app-generic-modal [closable]="true" bodyClasses="p-4">
  <ng-template appModalHeader>
    <h2 class="mb-0 me-4">
      {{ 'ADDRESS.DELIVERY_INFO_MODAL.TITLE' | translate }}
    </h2>
  </ng-template>
  <ng-template appModalBody>
    <h3>{{ order.dealer.companyName }}</h3>
    <p [innerHTML]="addressData"></p>
    <ng-container *ngIf="additionalRequirements.length">
      <h3>{{ 'ADDRESS.DELIVERY_INFO_MODAL.ADDITIONAL_REQUIREMENTS' | translate }}</h3>
      <p [innerHTML]="additionalRequirements"></p>
    </ng-container>
    <ng-container *ngIf="contacts.length">
      <h3>{{ 'ADDRESS.DELIVERY_INFO_MODAL.CONTACT_PERSON' | translate }}</h3>
      <p [innerHTML]="contacts"></p>
    </ng-container>
  </ng-template>
</app-generic-modal>
