import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeInputComponent } from './time-input.component';

@NgModule({
  declarations: [TimeInputComponent],
  imports: [NgbModule, CommonModule, FormsModule],
  exports: [TimeInputComponent],
})
export class TimeInputModule {}
