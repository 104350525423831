import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderArticleMaterialInputComponent } from './order-article-material-input.component';
import { FormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, FormsModule, NgbTypeaheadModule],
  declarations: [OrderArticleMaterialInputComponent],
  exports: [OrderArticleMaterialInputComponent],
})
export class OrderArticleMaterialInputModule {}
