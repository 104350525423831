<div 
  class="fw-bold hover-color-black cursor-pointer"
  [class.text-black]="checked"
  [ngClass]="cssClasses"
>
  <input 
    type="radio" 
    class="d-none"
    [id]="'label-' + radioGroup + '-' + value"
    [name]="radioGroup"
    [value]="value"
    [checked]="checked"
    (change)="select(value)"
  />
  <label 
    *ngIf="displayImage; else withoutImage"
    [for]="'label-' + radioGroup + '-' + value"
    class="d-flex flex-column gap-2"
  >
    <div class="rounded-1 ratio ratio-1x1 border border-2"
      [class.placeholder]="!image"
      [class.border-primary]="checked"
      [style.background-image]="'url('+(image || 'assets/images/configurator/configurator-property-placeholder.svg')+')'"
      [style.background-size]="'cover'"
    ></div>
    <div class="small">{{title}}</div>
  </label>
</div>

<ng-template #withoutImage>
  <label
    [for]="'label-' + radioGroup + '-' + value"
    class="d-flex w-full justify-content-between px-3 py-0875r hover-bg-gray-100"
  >
    <span>{{title}}</span>
    <i *ngIf="checked" class="icon ni-check text-primary"></i>
  </label>
</ng-template>
